













































































































































































































































































import {
  computed,
  ComputedRef,
  defineComponent,
  reactive,
  ref,
  Ref,
  watch,
  onMounted,
  onUnmounted
} from '@vue/composition-api'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { snakeCase } from 'lodash'

import {
  Color,
  IOrderDetail,
  Order,
  QuickInput,
  Variety,
  Item,
  Unit,
  AnyOrder,
  Size,
  Quality,
  IState,
  SizeGroup,
  QualityGroup,
  CommonProperty,
  VarietiesByItem,
  SizesBySizeGroup,
  QualitiesByQualityGroup,
  NurseryCompany,
  MappingKeyAndField
} from 'typings'
import {
  defaultObject,
  endpoints,
  showError,
  toCamelCase,
  urlPath,
  toSnakeCase,
  scrollTop,
  scrollBottom,
  getSortStates,
  matchData,
  frameBusEvent,
  backPage,
  checkIsOnPC,
  mappingKeyAndField
} from 'utils'
import { useQuery, useShowError } from 'hooks'
import { api, framebus } from 'plugins'
import {
  ChooseColor,
  ChooseNumber,
  ChooseProperty,
  ConfirmDelete,
  SelectInputRow,
  CreateCommonForm,
  ConfirmMerge,
  LoaderComponent,
  SingleDetailItem
} from 'components'
import moment from 'moment'

interface Property {
  id: number
  name: string
  defaultUnit?: {
    id: number
    name: string
  }
}

interface SetUp {
  orderDetail: IOrderDetail
  id: Ref<number | null>
  order: Ref<Order | null>
  showDelete: Ref<boolean>
  colors: Ref<Array<Color>>
  dialog: Ref<boolean>
  colorDialog: Ref<boolean>
  numberDialogValue: ComputedRef<number | string>
  numberDialogTitle: ComputedRef<string>
  numberDialog: Ref<boolean>
  isShowAction: Ref<boolean>
  setPropertyName: Ref<string>
  choosePropertyItems: Ref<Array<Property>>
  loading: Ref<boolean>
  quickInputDialog: Ref<boolean>
  codeValue: Ref<number | null>
  quickInputTooltip: Ref<boolean>
  save: () => void
  copyDetail: () => void
  getDialogTitle: () => string
  selectProperty: (action: string) => void
  createNew: (isDuplicate: boolean) => void
  updateOrderDetail: (action: string, item: Property) => void
  chooseProperty: (property: string) => void
  confirmDelete: (action: string) => Promise<void>
  selectQuickInput: (action: string, code: number) => void
  isShowDateDialog: Ref<boolean>
  showMerge: Ref<boolean>
  dataMergeGet: Ref<any>
  closeMergeDialog: (mode: string) => void
  saveCurrentStates: () => void
  uploadLoading: Ref<boolean>
  deleteLoading: Ref<boolean>
  close: () => void
  thisPage: Ref<string>
  sizeGroups: Ref<SizeGroup[]>
  qualityGroups: Ref<QualityGroup[]>
  nurseryCompanies: Ref<NurseryCompany[]>
  getNewData: (mode: string) => void
  itemSelectVariety: Ref<number>
  groupDataMaster: Ref<Array<any>>
  getData: () => void
  customerGroups: Ref<any[]>
  deleteOrder: () => void
  isOnPC: boolean
  addEventTyping: () => void
  removeEventTyping: () => void
}

const OrderDetailForm = defineComponent({
  components: {
    ChooseNumber,
    ChooseColor,
    ChooseProperty,
    SelectInputRow,
    ConfirmDelete,
    CreateCommonForm,
    ConfirmMerge,
    LoaderComponent,
    SingleDetailItem
  },
  setup(props, { root, refs }): SetUp {
    const thisPage = ref('order')
    const { $route, $toast, $router, $store } = root
    let { detailId, orderId, isDuplicated } = $route.params
    const { auctionDate } = $route.params
    const id = ref(Number(detailId) || null)
    const isShowDateDialog = ref(false)
    const uploadLoading = ref(false)
    const deleteLoading = ref(false)
    const listStateStore = ref<IState>($store.state.listStates)
    const onSaving = ref(false)
    const isOnPC = checkIsOnPC()
    const LIST_PROPERTY = [
      'quickInput',
      'auctionDate',
      'orderType',
      'customer',
      'buyerInfo',
      'item',
      'variety',
      'colors',
      'quality',
      'size',
      'quantity',
      'unit',
      'boxes',
      'boxType',
      'stems',
      'price',
      'amount'
    ]
    // const isReturnPage = ref(false)
    scrollTop()

    const listStates = ref<Array<string>>([])
    const initDetail: IOrderDetail = { ...defaultObject.initOrderDetail, orderId: Number(orderId) }

    const orderDetail: IOrderDetail = reactive({ ...initDetail })

    orderDetail.orderType = { id: 3, code: 3, name: '注文' }
    // orderDetail.unit = { id: 1, name: '本' }
    const initOrder = {
      id: null,
      auctionDate: auctionDate || moment().format('YYYY-MM-DD'),
      customer: {
        id: null,
        name: null,
        shortName: null
      }
    }
    const items = ref<Array<any>>([])
    const varieties = ref<Array<any>>([])
    const sizes = ref<Array<any>>([])
    const qualities = ref<Array<any>>([])
    const varietiesByItem = ref<Array<VarietiesByItem>>([])
    const sizeGroups = ref<Array<SizeGroup>>([])
    const qualityGroups = ref<Array<QualityGroup>>([])
    const nurseryCompanies = ref<Array<NurseryCompany>>([])
    const sizesBySizeGroup = ref<Array<SizesBySizeGroup>>([])
    const qualitiesByQualityGroup = ref<Array<QualitiesByQualityGroup>>([])
    const loading = ref(false)

    const order: AnyOrder = reactive({ ...initOrder })

    const itemSelectVariety = ref(1)
    const groupDataMaster = ref<any[]>([])
    const isShowAction = ref(false)
    const showDelete = ref(false)
    const showMerge = ref(false)
    const setPropertyName = ref('')
    const dialog = ref(false)
    const numberDialog = ref(false)
    const colorDialog = ref(false)
    const quickInputDialog = ref(false)
    const codeValue = ref<number | null>(null)
    const quickInputTooltip = ref(false)
    const dataMergeGet = ref<any>({
      id: null,
      itemName: '',
      varietyName: '',
      qualitySizeName: '',
      stems: 0,
      orderId: null,
      customerId: null,
      customerName: ''
    })
    const listStateSetting = ref<Array<string>>([])

    const choosePropertyItems = ref<Array<Property>>([])

    const harvestOrderState = computed(() => $store.state.harvest.harvestOrderState)

    const nextProperty = computed(() => {
      const key = setPropertyName.value
      if (key === listStates.value[listStates.value.length - 1]) {
        scrollBottom()
      }
      if (listStates.value.indexOf(key) === -1) {
        const indexListProp = LIST_PROPERTY.indexOf(key)
        for (let i = 0; i < LIST_PROPERTY.length; i += 1) {
          if (i > indexListProp && listStates.value.indexOf(LIST_PROPERTY[i]) !== -1) {
            return LIST_PROPERTY[i]
          }
        }
        return ''
      }
      // console.log(listStates.value, key)
      const indexKey = listStates.value.indexOf(key) + 1
      return listStates.value[indexKey]
    })

    const prevProperty = computed(() => {
      const key = setPropertyName.value
      if (listStates.value.indexOf(key) === -1) {
        const indexListProp = LIST_PROPERTY.indexOf(key)
        for (let i = LIST_PROPERTY.length - 1; i >= 0; i -= 1) {
          if (i < indexListProp && listStates.value.indexOf(LIST_PROPERTY[i]) !== -1) {
            return LIST_PROPERTY[i]
          }
        }
        return ''
      }
      const indexKey = listStates.value.indexOf(key) - 1
      return listStates.value[indexKey]
    })

    const { data: detailData, error } = useQuery(() =>
      detailId && detailId !== '0' ? `${endpoints.ORDER_DETAILS}${detailId}` : null
    )
    useShowError($toast, error)

    const { data: orderDataAPI } = useQuery(() =>
      orderId && orderId !== '0' ? `${endpoints.ORDERS}${orderId}` : null
    )

    const { data: quickInput } = useQuery(endpoints.QUICK_INPUT)

    const { data: units } = useQuery(endpoints.UNITS)
    const { data: colors } = useQuery(endpoints.COLORS)
    const { data: boxTypes } = useQuery(endpoints.BOX_TYPE)
    const { data: orderTypes } = useQuery(endpoints.ORDER_TYPES)
    const { data: customers } = useQuery(endpoints.CUSTOMERS)
    const { data: customerGroups } = useQuery(endpoints.CUSTOMER_GROUP)
    const getVarietyByItem = async () => {
      try {
        if ($store.state.common.allVarietyByItem.length === 0) {
          const { data } = await api.get(`${endpoints.VARIETIES}group_by_item`)
          varietiesByItem.value = toCamelCase(data)
          $store.commit('setAllVarietyByItem', varietiesByItem.value)
        } else {
          varietiesByItem.value = $store.state.common.allVarietyByItem
        }
      } catch (e) {
        showError(e, $toast, root.$t('common.get_data_failed') as string)
      }
    }

    const getData = async (): Promise<void> => {
      loading.value = true
      await getVarietyByItem()
      try {
        const data = await Promise.all([
          api.get(`${endpoints.QUALITIES}grouped_by_quality_group`),
          api.get(`${endpoints.SIZES}grouped_by_size_group`),
          api.get(endpoints.NURSERY_COMPANY)
        ])

        const [
          { data: qualitiesByQualityGroupData },
          { data: sizesBySizeGroupData },
          { data: nurseryCompaniesData }
        ] = data

        qualitiesByQualityGroup.value = toCamelCase(qualitiesByQualityGroupData)
        sizesBySizeGroup.value = toCamelCase(sizesBySizeGroupData)
        nurseryCompanies.value = toCamelCase(nurseryCompaniesData)

        const match: any = matchData(
          varietiesByItem.value,
          sizesBySizeGroup.value,
          qualitiesByQualityGroup.value
        )

        items.value = match.items
        varieties.value = match.varieties
        sizes.value = match.sizes
        sizeGroups.value = match.sizeGroups
        qualities.value = match.qualities
        qualityGroups.value = match.qualityGroups
      } catch (e) {
        showError(e, $toast, root.$t('common.get_data_failed') as string)
      } finally {
        loading.value = false
      }
    }

    watch(
      () => $store.state.listStates.order,
      () => {
        if ($store.state.listStates.order.length === 0) {
          listStateStore.value.order = listStateSetting.value
          $store.commit('setListStates', listStateStore.value)
        }
        listStates.value = getSortStates(
          defaultObject.listOrderStates,
          $store.state.listStates.order,
          listStateSetting.value
        )
      }
    )

    const getDefaultStates = async () => {
      try {
        const { data } = await api.get(`${endpoints.COMMON}get_setting`)
        listStateSetting.value = toCamelCase(data).setting.states?.order
          ? toCamelCase(data).setting.states.order
          : []
        if (listStateStore.value.order.length === 0) {
          listStateStore.value.order = listStateSetting.value
          $store.commit('setListStates', listStateStore.value)
        }
        listStates.value = getSortStates(
          defaultObject.listOrderStates,
          $store.state.listStates.order,
          listStateSetting.value
        )
      } catch (e) {
        showError(e, $toast, root.$t('order.msg.get_data_failed') as string)
      }
    }

    // filtered size choices by size group of chosen item or variety
    const filteredSizes: Ref<Array<Size>> = ref([])

    watch(
      () => items.value,
      () => {
        if (harvestOrderState.value.item) {
          const dataItem = items.value.filter((item: Item) => {
            return item.id === harvestOrderState.value.item.id
          })
          orderDetail.unit = dataItem[0].defaultUnit
        }
      }
    )

    const varietyList = computed(() => {
      const itemId = orderDetail.item?.id

      if (!itemId) return []

      return varieties.value.filter((variety: Variety) => variety.item.id === itemId)
    })

    const sizeList = computed(() => {
      let result: Array<Size> = []

      const itemSizeGroupId = (orderDetail.item as Item)?.sizeGroup?.id || null
      const varietySizeGroupId = (orderDetail.variety as Variety)?.sizeGroup?.id || null
      const existedSizeId = (orderDetail.size as Size)?.id || null

      // case create new order detail
      if (!varietySizeGroupId && !itemSizeGroupId) {
        // set to all sizes
        result = JSON.parse(JSON.stringify(sizes.value))
        return result
      }

      if (varietySizeGroupId) {
        // if variety has sizeGroup: filter by variety sizeGroup
        result = sizes.value.filter((size: Size) => {
          return size.sizeGroup.id === varietySizeGroupId
        })
      } else if (itemSizeGroupId) {
        // if item has sizeGroup: filter by item sizeGroup
        result = sizes.value.filter((size: Size) => {
          return size.sizeGroup.id === itemSizeGroupId
        })
      }

      // case update existed order detail
      // add existed size if filtered list doesn't contain that size
      if (existedSizeId) {
        const containCurrentSize = result.some((size) => size.id === existedSizeId)
        if (!containCurrentSize) {
          result.push(orderDetail.size as Size)
        }
      }
      return result
    })

    const qualityList = computed(() => {
      let result: Array<Quality> = []

      const itemQualityGroupId = (orderDetail.item as Item)?.qualityGroup?.id || null
      const varietyQualityGroupId = (orderDetail.variety as Variety)?.qualityGroup?.id || null
      const existedQualityId = (orderDetail.size as Quality)?.id || null

      // case create new order detail
      if (!varietyQualityGroupId && !itemQualityGroupId) {
        // set to all sizes
        result = JSON.parse(JSON.stringify(qualities.value))
        return result
      }

      if (varietyQualityGroupId) {
        // if variety has qualityGroup: filter by variety qualityGroup
        result = qualities.value.filter((quality: Quality) => {
          return quality.qualityGroup.id === varietyQualityGroupId
        })
      } else if (itemQualityGroupId) {
        // if item has qualityGroup: filter by item qualityGroup
        result = qualities.value.filter((quality: Quality) => {
          return quality.qualityGroup.id === itemQualityGroupId
        })
      }

      // case update existed order detail
      // add existed quality if filtered list doesn't contain that quality
      if (existedQualityId) {
        const containCurrentQuality = result.some((quality) => quality.id === existedQualityId)
        if (!containCurrentQuality) {
          result.push(orderDetail.quality as Quality)
        }
      }
      return result
    })

    const unitList = computed(() => {
      const itemId = orderDetail.item?.id

      if (!itemId) return []

      const currentItem = items.value.find((item: Item) => item.id === itemId)
      const unitOfItemIds = currentItem.units.map((unit: Unit) => unit.id)

      if (unitOfItemIds.length === 0) return units.value

      // List unit(default and units) of item
      const unitIds = [currentItem.defaultUnit?.id, orderDetail.unit?.id, ...unitOfItemIds]

      return units.value.filter((unit: Unit) => unitIds.includes(unit.id))
    })

    const numberDialogValue = computed(() => {
      const defaultValue = setPropertyName.value === 'buyerInfo' ? '' : 0
      return orderDetail[setPropertyName.value] || defaultValue
    })

    const numberDialogTitle = computed((): string => {
      return root.$t(`master.${snakeCase(setPropertyName.value)}`) as string
    })

    const chooseProperty = (property: string) => {
      setPropertyName.value = property
      dialog.value = false
      colorDialog.value = false
      numberDialog.value = false
      isShowDateDialog.value = false
      quickInputDialog.value = false

      switch (property) {
        case 'quickInput':
          quickInputDialog.value = true
          break
        case 'auctionDate':
          isShowDateDialog.value = true
          break
        case 'customer':
          choosePropertyItems.value = customers.value
          dialog.value = true
          break
        case 'item':
          choosePropertyItems.value = items.value
          dialog.value = true
          break
        case 'variety':
          choosePropertyItems.value = varietyList.value
          groupDataMaster.value = varietiesByItem.value
          dialog.value = true
          break
        case 'size':
          choosePropertyItems.value = sizeList.value
          groupDataMaster.value = sizesBySizeGroup.value
          dialog.value = true
          break
        case 'boxType':
          choosePropertyItems.value = boxTypes.value
          dialog.value = true
          break
        case 'colors':
          colorDialog.value = true
          break
        case 'unit':
          choosePropertyItems.value = unitList.value
          dialog.value = true
          break
        case 'orderType':
          choosePropertyItems.value = orderTypes.value
          dialog.value = true
          break
        case 'quality':
          choosePropertyItems.value = qualityList.value
          groupDataMaster.value = qualitiesByQualityGroup.value
          dialog.value = true
          break
        case 'quantity':
          numberDialog.value = true
          break
        case 'boxes':
          numberDialog.value = true
          break
        case 'price':
          numberDialog.value = true
          break
        case 'stems':
          numberDialog.value = true
          break
        case 'buyerInfo':
          numberDialog.value = true
          break
        case 'amount':
          numberDialog.value = true
          break
        default:
      }
    }

    const selectProperty = (action: string): void => {
      orderDetail.stems = orderDetail.stems === 0 ? null : orderDetail.stems
      orderDetail.boxes = orderDetail.boxes === 0 ? null : orderDetail.boxes
      orderDetail.quantity = orderDetail.quantity === 0 ? null : orderDetail.quantity
      orderDetail.price = orderDetail.price === 0 ? null : orderDetail.price
      orderDetail.amount = orderDetail.amount === 0 ? null : orderDetail.amount
      if (action === 'next') {
        chooseProperty(nextProperty.value)
      } else if (action === 'prev') {
        chooseProperty(prevProperty.value)
      }
    }

    const updateOrderDetail = (
      action: string,
      item: CommonProperty | number | Item | string
    ): void => {
      const key = setPropertyName.value as keyof IOrderDetail
      const oldValue = orderDetail[key]
      orderDetail[key] = item
      if (key === 'item') {
        orderDetail.variety = null
        if (
          oldValue?.sizeGroup?.id !== orderDetail[key]?.sizeGroup?.id ||
          orderDetail[key]?.sizeGroup?.id !== orderDetail.size?.sizeGroup?.id
        ) {
          orderDetail.size = null
        }
        if (
          oldValue?.qualityGroup?.id !== orderDetail[key]?.qualityGroup?.id ||
          orderDetail[key]?.qualityGroup?.id !== orderDetail.quality?.qualityGroup?.id
        ) {
          orderDetail.quality = null
        }
        orderDetail.unit = (item as Item).defaultUnit
        itemSelectVariety.value = Number((item as Item).id)
      }

      if (key === 'variety') {
        const compareValue = oldValue ?? orderDetail.item
        if (
          compareValue?.sizeGroup?.id !== orderDetail[key]?.sizeGroup?.id ||
          orderDetail[key]?.sizeGroup?.id !== orderDetail.size?.sizeGroup?.id
        ) {
          orderDetail.size = null
        }
        if (
          compareValue?.qualityGroup?.id !== orderDetail[key]?.qualityGroup?.id ||
          orderDetail[key]?.qualityGroup?.id !== orderDetail.quality?.qualityGroup?.id
        ) {
          orderDetail.quality = null
        }
      }
      if (key === 'customer') {
        order[key] = item
      }
      selectProperty(action)
    }

    const generateBodyOrderDetail = () => {
      return {
        orderId: orderDetail.orderId,
        quantity: orderDetail.quantity,
        boxes: orderDetail.boxes,
        price: orderDetail.price,
        amount: orderDetail.amount,
        stems: orderDetail.stems,
        remark: orderDetail.remark?.trim(),
        isSpecial: orderDetail.isSpecial ? orderDetail.isSpecial : false,
        buyer_info: orderDetail.buyerInfo,
        itemId: orderDetail.item?.id,
        varietyId: orderDetail.variety?.id,
        sizeId: orderDetail.size?.id,
        boxTypeId: orderDetail.boxType?.id,
        unitId: orderDetail.unit?.id,
        orderTypeId: orderDetail.orderType?.id,
        harvestResultIds: orderDetail.harvestResultIds,
        colorIds: orderDetail.colors.map((color: Color) => color.id).sort(),
        qualityId: orderDetail.quality?.id,
        isAssigned: orderDetail.isAssigned || false
      }
    }

    const valiadate = async (): Promise<boolean> => {
      const validateAuctionDate = await (
        refs.auctionDate as InstanceType<typeof ValidationProvider>
      ).validate()
      const validateCustomer = await (
        refs.customer as InstanceType<typeof ValidationProvider>
      ).validate()
      const validateOrderType = await (
        refs.orderType as InstanceType<typeof ValidationProvider>
      ).validate()
      return validateAuctionDate.valid && validateCustomer.valid && validateOrderType.valid
    }

    const generateBodyOrder = () => {
      return {
        auctionDate: order.auctionDate,
        customerId: order.customer.id
      }
    }

    const closeMergeDialog = async (mode: string) => {
      if (mode === 'delete') {
        const requestBodyOrderDetail = generateBodyOrderDetail()
        const requestBodyOrder = generateBodyOrder()
        const requestBody = ref<any>({})
        requestBody.value = { ...requestBodyOrderDetail, ...requestBodyOrder }
        if (
          typeof requestBody.value.stems === 'number' &&
          typeof dataMergeGet.value.stems === 'number'
        ) {
          requestBody.value.stems += dataMergeGet.value.stems
        }
        if (
          typeof requestBody.value.boxes === 'number' &&
          typeof dataMergeGet.value.boxes === 'number'
        ) {
          requestBody.value.boxes += dataMergeGet.value.boxes
        }
        requestBody.value.orderId = dataMergeGet.value.orderId
        try {
          requestBody.value = toSnakeCase(requestBody.value)
          await api.put(`${endpoints.ORDER_DETAILS}${dataMergeGet.value.id}`, requestBody.value)
          if (Number(detailId) !== 0) {
            await api.delete(`${endpoints.ORDER_DETAILS}${detailId}`)
            await api.delete(`${endpoints.ORDERS}${orderId}`)
          }
          $toast.success(root.$t('order.msg.merge_successful'))
          // if (isReturnPage.value) {
          //   returnPage()
          // }
        } catch (e) {
          showError(e, $toast, root.$t('order.msg.merge_failed') as string)
        }
      }
      showMerge.value = false
    }

    // const checkIsExitOrderDetailAndOrder = async (): Promise<boolean> => {
    //   try {
    //     const requestBodyOrderDetail = generateBodyOrderDetail()
    //     const requestBodyOrder = generateBodyOrder()
    //     const requestBody = ref<any>({
    //       ...requestBodyOrderDetail,
    //       ...requestBodyOrder
    //     })
    //     requestBody.value.detailId = detailId
    //     requestBody.value = toSnakeCase(requestBody.value)
    //     const { data } = await api.post(`${endpoints.ORDER_DETAILS}check_merge`, requestBody.value)
    //     dataMergeGet.value = toCamelCase(data)
    //     if (dataMergeGet.value.orderId !== null) {
    //       return true
    //     }
    //   } catch (e) {
    //     showError(e, $toast, root.$t('order.msg.create_order_failed') as string)
    //   }
    //   return false
    // }

    const createOrderAndOrderDetail = async (): Promise<boolean> => {
      uploadLoading.value = true
      try {
        if (await valiadate()) {
          // if (await checkIsExitOrderDetailAndOrder()) {
          //   showMerge.value = true
          // } else {

          const requestBodyOrder = toSnakeCase(generateBodyOrder())
          const { data } = await api.post(`${endpoints.ORDERS}`, requestBodyOrder)
          orderDetail.orderId = toCamelCase(data).id
          orderId = toCamelCase(data).id
          const requestBodyOrderDetail = toSnakeCase(generateBodyOrderDetail())
          detailId = (await api.post(`${endpoints.ORDER_DETAILS}`, requestBodyOrderDetail)).data.id
          $toast.success(root.$t('order.msg.create_successful'))
          return true

          // }
          // return false
        }
      } catch (e) {
        showError(e, $toast, root.$t('common.msg.system_failure') as string)
      } finally {
        await setTimeout(() => {
          uploadLoading.value = false
        }, 200)
      }

      return false
    }

    const updateOrderAndOrderDetail = async (): Promise<boolean> => {
      uploadLoading.value = true
      try {
        if (await valiadate()) {
          // if (await checkIsExitOrderDetailAndOrder()) {
          //   showMerge.value = true
          // } else {

          const requestBodyOrderDetail = toSnakeCase(generateBodyOrderDetail())
          const requestBodyOrder = toSnakeCase(generateBodyOrder())
          await api.put(`${endpoints.ORDERS}${orderId}`, requestBodyOrder)
          await api.put(`${endpoints.ORDER_DETAILS}${detailId}`, requestBodyOrderDetail)
          $toast.success(root.$t('order.msg.update_successful'))
          return true

          // }
          // return false
        }
      } catch (e) {
        showError(e, $toast, root.$t('common.msg.system_failure') as string)
      } finally {
        await setTimeout(() => {
          uploadLoading.value = false
        }, 200)
      }

      return false
    }

    const deleteDetail = async (): Promise<boolean> => {
      deleteLoading.value = true
      try {
        await api.delete(`${endpoints.ORDERS}${orderId}`)
        await api.delete(`${endpoints.ORDER_DETAILS}${detailId}`)
        $toast.success(root.$t('common.msg.delete_success'))
        return true
      } catch (e) {
        showError(e, $toast, root.$t('common.msg.system_failure'))
      } finally {
        deleteLoading.value = false
      }
      return false
    }

    const saveCurrentStates = async () => {
      try {
        const setting = { data: $store.state.listStates.order, key: 'order' }
        await api.put(`${endpoints.COMMON}update_setting_list_states_default`, setting)
        $toast.success(root.$t('common.msg.save_input_state_successful'))
        isShowAction.value = false
      } catch (e) {
        showError(e, $toast, root.$t('common.msg.system_failure') as string)
      }
    }

    const deleteOrder = () => {
      // remove keydown event to prevent overwrite delete confirm dialog event
      // eslint-disable-next-line no-use-before-define
      document.removeEventListener('keydown', onTyping)

      showDelete.value = true
    }

    const confirmDelete = async (action: string): Promise<void> => {
      let success = false
      if (action === 'delete') {
        success = await deleteDetail()
      }
      if (success) {
        backPage()
      }
      showDelete.value = false

      // Add keydown event
      // eslint-disable-next-line no-use-before-define
      document.addEventListener('keydown', onTyping)
    }

    const close = () => {
      backPage()
    }

    const replacePageState = (): void => {
      const orderDetailId = id.value
      if (orderDetailId) {
        $router
          .replace({
            name: urlPath.ORDER_DETAIL_FORM.name,
            params: { orderId, detailId }
          })
          .catch((e) => {
            console.log(e)
          })
      }
    }

    const copyDetail = (): void => {
      replacePageState()
    }

    const validateOrder = async (): Promise<boolean> => {
      const form = refs.form as InstanceType<typeof ValidationObserver>
      const valid = await form.validate()
      return valid
    }

    const createOrUpdateDetail = async (): Promise<boolean> => {
      const valid = await validateOrder()
      if (!valid) return false

      let success = false
      if (id.value) {
        // success = await updateDetail()
        success = await updateOrderAndOrderDetail()
      } else {
        success = await createOrderAndOrderDetail()
      }

      return success
    }

    const createNew = async (isDuplicate: boolean): Promise<void> => {
      codeValue.value = null
      id.value = null
      orderId = '0'
      detailId = '0'

      const filterKey =
        harvestOrderState.value.ids.length !== 0
          ? ['harvestResultIds', 'item', 'variety', 'size', 'quality']
          : []
      const keys = Object.keys(initDetail).filter((key: string) => !filterKey.includes(key))
      if (!isDuplicate) {
        keys.forEach((key) => {
          orderDetail[key] = initDetail[key]
        })
        const keysOrder = Object.keys(initOrder)
        keysOrder.forEach((key) => {
          order[key] = initDetail[key]
          if (key === 'auctionDate') {
            order[key] = moment(new Date()).format('YYYY-MM-DD')
          }
          orderDetail.orderType = { id: 3, code: 3, name: '注文' }
        })
      } else {
        orderDetail.stems = initDetail.stems
        orderDetail.boxes = initDetail.boxes
        orderDetail.amount = initDetail.amount
        orderDetail.harvestResultIds = initDetail.harvestResultIds
        orderDetail.isAssigned = initDetail.isAssigned
      }
      replacePageState()
      const form = refs.form as InstanceType<typeof ValidationObserver>
      form?.reset()
      scrollTop()
    }

    const save = async (): Promise<void> => {
      if (onSaving.value) {
        return
      }
      onSaving.value = true
      const success = await createOrUpdateDetail()
      if (id.value === null && success) {
        $router
          .replace({
            name: urlPath.ORDER_DETAIL_FORM.name,
            params: { orderId, detailId }
          })
          .catch((e) => {
            console.log(e)
          })
      }
      id.value = Number(orderId)
      // update calendar
      if (success) {
        framebus.emit(frameBusEvent.UPDATE_CALENDAR, { isUpdate: true })
      }
      if (isOnPC) {
        setTimeout(() => {
          onSaving.value = false
        }, 300)
      } else {
        onSaving.value = false
      }
    }

    const getDialogTitle = (): string => {
      return root.$t(`master.${snakeCase(setPropertyName.value)}.title`) as string
    }

    watch(detailData, (value) => {
      Object.keys(value).forEach((key) => {
        orderDetail[key] = value[key]
      })
      itemSelectVariety.value = orderDetail.item ? orderDetail.item.id : 1
    })

    watch(orderDataAPI, (value) => {
      Object.keys(value).forEach((key) => {
        order[key] = value[key]
      })
    })

    /*
     * filter size choice when enter order detail page
     * */
    const initFilteredSizes = () => {
      // filter size choices by orderDetail item/variety's sizeGroup value
      if (detailId && detailId !== '0') {
        const itemSizeGroupId = (orderDetail.item as Item)?.sizeGroup?.id || null
        const varietySizeGroupId = (orderDetail.variety as Variety)?.sizeGroup?.id || null
        const existedSizeId = (orderDetail.size as Size)?.id || null

        // if variety has sizeGroup: filter by variety sizeGroup
        if (varietySizeGroupId) {
          filteredSizes.value = sizes.value.filter((size: Size) => {
            return size.sizeGroup.id === varietySizeGroupId
          })
        } else if (itemSizeGroupId) {
          // if item has sizeGroup: filter by item sizeGroup
          filteredSizes.value = sizes.value.filter((size: Size) => {
            return size.sizeGroup.id === itemSizeGroupId
          })
        } else {
          // set to all sizes
          filteredSizes.value = JSON.parse(JSON.stringify(sizes.value))
        }

        // add existed size if filtered list doesn't contain that size
        const containCurrentSize = filteredSizes.value.some((size) => size.id === existedSizeId)
        if (!containCurrentSize) {
          filteredSizes.value.push(orderDetail.size as Size)
        }
        return
      }

      // if create new order detail, set to all sizes
      filteredSizes.value = JSON.parse(JSON.stringify(sizes.value))
    }

    watch(sizes, () => {
      // init filteredSizes when entered to the page
      // NOTE: sizes value doesn't change throughout the page
      initFilteredSizes()
    })

    watch(
      () => [orderDetail.boxes, orderDetail.quantity],
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ([boxes, quantity, stems], [preBoxes, preQuantity, preStems]) => {
        if (!orderDetail.stems || (preBoxes !== null && quantity !== null)) {
          orderDetail.stems = Number(orderDetail.boxes * orderDetail.quantity)
        }
      }
    )

    watch(
      () => orderDetail.stems,
      () => {
        if (orderDetail.stems === null) {
          if (orderDetail.boxes && orderDetail.quantity) {
            orderDetail.stems = Number(orderDetail.boxes * orderDetail.quantity)
          }
        } else if (Number(orderDetail.stems) === 0) {
          if (orderDetail.boxes && orderDetail.quantity) {
            orderDetail.stems = Number(orderDetail.boxes * orderDetail.quantity)
          }
        }
      }
    )

    watch(
      () => [orderDetail.price, orderDetail.stems],
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ([price, stems]) => {
        if (price !== null && stems !== null) {
          orderDetail.amount = Number(price * stems)
        }
      }
    )

    const selectQuickInput = (action: string, code: number) => {
      codeValue.value = code || null
      quickInputDialog.value = false

      const orderData = quickInput.value.find((element: QuickInput) => {
        return element.code === code
      })

      if (orderData) {
        orderDetail.item = orderData.item
        orderDetail.variety = orderData.variety
        orderDetail.size = orderData.size
        orderDetail.unit = orderData.unit
        orderDetail.quality = orderData.quality
        orderDetail.quantity = orderData.quantity
      }
      selectProperty(action)
    }

    const onTyping = (event: any) => {
      if (event.keyCode === 13 && !event.shiftKey) {
        // Press enter
        save()
      }

      if (id.value) {
        if (event.keyCode === 67) {
          // Press C
          createNew(true)
        } else if (event.keyCode === 78) {
          // Press N
          createNew(false)
        } else if (event.keyCode === 27) {
          // Press Esc
          close()
        } else if (event.keyCode === 68) {
          // Press D
          deleteOrder()
        }
      }

      if (!event.shiftKey && !event.ctrlKey && event.keyCode >= 48 && event.keyCode <= 57) {
        const field = mappingKeyAndField.find(
          (e: MappingKeyAndField) => e.keyCode === event.keyCode
        )
        if (field) {
          chooseProperty(field.field)
        }
      }
    }

    onMounted(async () => {
      await getData()

      if (detailData.value) {
        Object.keys(detailData.value).forEach((key) => {
          orderDetail[key] = detailData.value[key]
        })
      }

      // Create order from harvest result
      const { item, variety, size, quality, ids, result } = harvestOrderState.value
      if (ids.length !== 0) {
        orderDetail.item = item
        orderDetail.variety = variety
        orderDetail.size = size
        orderDetail.quality = quality
        orderDetail.harvestResultIds = ids

        if (result) {
          Object.keys(orderDetail)
            .filter((key) => key !== 'stems' && key !== 'boxes')
            .forEach((key) => {
              orderDetail[key] = result[key as keyof IOrderDetail] || orderDetail[key]
            })
          orderDetail.price = result.desiredPrice
          orderDetail.colors = [result.color]
        }
      }
      getDefaultStates()
      if (isDuplicated === '1') {
        createNew(isDuplicated === '1')
        // reset value
        setTimeout(() => {
          orderDetail.stems = initDetail.stems
          orderDetail.boxes = initDetail.boxes
          orderDetail.amount = initDetail.amount
          orderDetail.harvestResultIds = initDetail.harvestResultIds
        }, 1500)
        isDuplicated = '0'
      }
      document.addEventListener('keydown', onTyping)
    })

    const addEventTyping = (): void => {
      document.addEventListener('keydown', onTyping)
    }

    const removeEventTyping = (): void => {
      document.removeEventListener('keydown', onTyping)
    }

    const getNewData = (mode: string) => {
      let listID = []
      let newId = []
      let idQualityGroup = 0
      const newData = $store.state.common.newDataMaster
      // itemSelectVariety.value = 1
      switch (mode) {
        case 'customer':
          listID = customers.value.map((item: any) => Number(item.id))
          newId = listID.filter((e: number) => e === $store.state.common.newDataMaster.id)
          if (newId.length === 0) {
            customers.value.push({ ...newData })
          }
          break
        case 'item':
          items.value.push({ ...newData })
          varietiesByItem.value.push({ ...newData, varieties: [] })
          groupDataMaster.value = varietiesByItem.value
          $store.commit('setAllVarietyByItem', varietiesByItem.value)
          // eslint-disable-next-line no-restricted-globals
          // parent.postMessage(varietiesByItem.value, '*')
          framebus.emit(frameBusEvent.ITEM_VARIETY, {
            contents: varietiesByItem.value
          })
          itemSelectVariety.value = $store.state.common.newDataMaster.id
          break
        case 'variety':
          listID = varieties.value.map((item: any) => Number(item.id))
          newId = listID.filter((e: number) => e === $store.state.common.newDataMaster.id)
          if (newId.length === 0) {
            varieties.value.push({ ...newData })
            varietiesByItem.value.map((varietyByItem: VarietiesByItem, index: number) => {
              if (varietyByItem.id === newData.item.id) {
                varietiesByItem.value[index].varieties.push(newData)
              }
              return null
            })
            $store.commit('setAllVarietyByItem', varietiesByItem.value)
            groupDataMaster.value = varietiesByItem.value
            framebus.emit(frameBusEvent.ITEM_VARIETY, {
              contents: varietiesByItem.value
            })
          }
          break
        case 'size':
          listID = sizes.value.map((item: any) => Number(item.id))
          newId = listID.filter((e: number) => e === $store.state.common.newDataMaster.id)
          if (newId.length === 0) {
            sizes.value.push({ ...newData })
            sizesBySizeGroup.value.push({ ...newData, sizes: [] })
            groupDataMaster.value = sizesBySizeGroup.value
          }
          break
        case 'box_type':
          listID = boxTypes.value.map((item: any) => Number(item.id))
          newId = listID.filter((e: number) => e === $store.state.common.newDataMaster.id)
          if (newId.length === 0) {
            boxTypes.value.push({ ...newData })
          }
          break
        case 'quality':
          listID = qualities.value.map((item: any) => Number(item.id))
          newId = listID.filter((e: number) => e === $store.state.common.newDataMaster.id)
          if (newId.length === 0) {
            idQualityGroup = newData.qualityGroup.id
            qualitiesByQualityGroup.value.map((e: QualityGroup, index: number) => {
              if (e.id === idQualityGroup) {
                qualitiesByQualityGroup.value[index].qualities.push({ ...newData })
              }
              return null
            })
            qualities.value.push({ ...newData })
            groupDataMaster.value = qualitiesByQualityGroup.value
          }
          break
        case 'unit':
          listID = units.value.map((item: any) => Number(item.id))
          newId = listID.filter((e: number) => e === $store.state.common.newDataMaster.id)
          if (newId.length === 0) {
            units.value.push({ ...newData })
          }
          break
        default:
      }
      chooseProperty(setPropertyName.value)
      updateOrderDetail('next', newData)
    }

    onUnmounted(() => {
      $store.commit('resetHarvestOrderState')
      document.removeEventListener('keydown', onTyping)
    })

    watch(
      () => [
        dialog.value,
        numberDialog.value,
        colorDialog.value,
        isShowDateDialog.value,
        quickInputDialog.value
      ],
      ([showDialog, showNumberDialog, showColorDialog, showDateDialog, showQuickInputDialog]) => {
        if (
          showDialog ||
          showNumberDialog ||
          showColorDialog ||
          showDateDialog ||
          showQuickInputDialog
        ) {
          document.removeEventListener('keydown', onTyping)
        } else {
          document.addEventListener('keydown', onTyping)
        }
      }
    )

    return {
      id,
      save,
      order,
      colors,
      dialog,
      copyDetail,
      showDelete,
      orderDetail,
      colorDialog,
      isShowAction,
      numberDialog,
      numberDialogTitle,
      numberDialogValue,
      confirmDelete,
      getDialogTitle,
      setPropertyName,
      chooseProperty,
      choosePropertyItems,
      updateOrderDetail,
      selectProperty,
      createNew,
      loading,
      quickInputDialog,
      codeValue,
      selectQuickInput,
      quickInputTooltip,
      isShowDateDialog,
      showMerge,
      dataMergeGet,
      closeMergeDialog,
      uploadLoading,
      deleteLoading,
      saveCurrentStates,
      close,
      thisPage,
      sizeGroups,
      qualityGroups,
      nurseryCompanies,
      getNewData,
      itemSelectVariety,
      groupDataMaster,
      getData,
      customerGroups,
      deleteOrder,
      isOnPC,
      addEventTyping,
      removeEventTyping
    }
  }
})

export default OrderDetailForm
